import { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import Masonry from 'react-masonry-css'
import useTranslation from 'next-translate/useTranslation'

import device from 'utils/device'
// import Image from 'components/Image'
import Svg from 'components/Svg'
import Reveal from 'components/Reveal'

const breakpointColumnsObj = {
    default: 3,
    750: 1
}

export default function Balance() {
    const { t } = useTranslation('home')

    const [showMore, setShowMore] = useState(false)
    const [limit, setLimit] = useState(false)

    const items = t('home_reviews_list', {}, { returnObjects: true })

    const handleToggle = useCallback(() => {
        setShowMore(state => !state)
    }, [])

    const data = useMemo(() => {
        return items.slice(0, showMore ? 100 : limit)
    }, [showMore, limit])

    useEffect(() => {
        setLimit(window.outerWidth > 750 ? 3 : 2)
    }, [])

    return (
        <Reveal>
            <Container>
                <Quote viewBox="0 0 80 80">
                    <path d="M0 0h80v80H0Z" fill="none" />
                    <path d="M23.9 56.666a4.46 4.46 0 0 0 4-2.467l4.733-9.466a6.58 6.58 0 0 0 .7-2.967v-15.1A3.343 3.343 0 0 0 30 23.333H16.666a3.343 3.343 0 0 0-3.333 3.333V40a3.343 3.343 0 0 0 3.333 3.333h6.667L19.9 50.2a4.462 4.462 0 0 0 4 6.466Zm33.333 0a4.46 4.46 0 0 0 4-2.467l4.733-9.466a6.58 6.58 0 0 0 .7-2.967v-15.1a3.343 3.343 0 0 0-3.333-3.333H50a3.343 3.343 0 0 0-3.333 3.333V40A3.343 3.343 0 0 0 50 43.333h6.667L53.233 50.2a4.462 4.462 0 0 0 4 6.466Z" />
                </Quote>
                <Wrap>
                    <Items>
                        <Masonry breakpointCols={breakpointColumnsObj} className="masonry" columnClassName="my-masonry-grid_column">
                            {data.map((item, index) => (
                                <Item key={index}>
                                    <Content>
                                        <User>
                                            {/* <ImageContainer>
                                                <Image src="/images/review-user.png" alt={item.name} layout="fill" />
                                            </ImageContainer> */}
                                            <Right>
                                                <strong>{item.name}</strong>
                                                <span>{item.place}</span>
                                            </Right>
                                        </User>
                                        <Stars>
                                            <Svg name="stars"/>
                                        </Stars>
                                        <p>{item.description}</p>
                                    </Content>
                                </Item>
                            ))}
                        </Masonry>
                        {!showMore && (
                            <Overlay xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 360">
                                <defs>
                                    <linearGradient id="a" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
                                        <stop offset="0" stopColor="#f0f0f0" stopOpacity="0" />
                                        <stop offset="1" stopColor="#f0f0f0" />
                                    </linearGradient>
                                </defs>
                                <path fill="url(#a)" d="M0 0h1366v360H0z" />
                            </Overlay>
                        )}
                    </Items>
                    <More showMore={showMore} onClick={handleToggle}>
                        <strong>{showMore ? 'Show less' : 'Show more'}</strong>
                        <svg viewBox="0 0 24 24">
                            <path d="M18.3 14.29a1 1 0 0 0-1.41 0L13 18.17V3a1 1 0 0 0-1-1 1 1 0 0 0-1 1v15.18L7.12 14.3a1 1 0 0 0-1.41 0 1 1 0 0 0 0 1.41l5.59 5.59a1 1 0 0 0 1.41 0l5.59-5.59a1.027 1.027 0 0 0 0-1.42Z" />
                        </svg>
                    </More>
                </Wrap>
            </Container>
        </Reveal>
    )
}

const Container = styled.div`
    padding: 0 0 1.6rem 0;
    ${device.mobile} {
        padding: 0rem 0 0.48rem 0;
    }
`
// padding: 0.8rem 0 0.48rem 0;

const Quote = styled.svg`
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 auto 0.68rem auto;
    display: block;
    ${device.mobile} {
        margin: 0 auto 0.28rem auto;
    }
`

const Wrap = styled.div`
    margin: 0 auto;
    width: var(--wrap);
    ${device.mobile} {
        width: var(--wrap-mobile);
    }
`

const Items = styled.div`
    margin-left: -0.12rem;
    margin-right: -0.12rem;
    position: relative;
    ${device.mobile} {
        margin-left: -0.08rem;
        margin-right: -0.08rem;
    }
`

const Item = styled.div`
    padding: 0.12rem;
    ${device.mobile} {
        padding: 0.08rem;
    }
`

const Stars = styled.div`
    svg {
        width: 1.28rem;
        height: 0.24rem;
    }
`

const Content = styled.div`
    border-radius: 0.24rem;
    padding: 0.36rem;
    background: var(--color-white);
    p {
        font-size: 0.2rem;
        margin: 0.16rem 0 0 0;
    }
    ${device.mobile} {
        padding: 0.24rem;
        p {
            font-size: 0.18rem;
            margin: 0 0 0.24rem;
        }
    }
`

const User = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.16rem;
`

const Right = styled.div`
    > * {
        display: block;
        margin: 0.04rem 0;
    }
`

// const ImageContainer = styled.div`
//     width: 0.6rem;
//     height: 0.6rem;
//     border-radius: 50%;
//     position: relative;
//     margin-right: 0.16rem;
// `

const Overlay = styled.svg`
    width: 13.66rem;
    height: 3.6rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -6.83rem;
`

const More = styled.div`
    display: flex;
    user-select: none;
    flex-direction: column;
    cursor: pointer;
    margin: 0 auto;
    align-items: center;
    padding: 0.4rem 0;
    svg {
        width: 0.24rem;
        height: 0.24rem;
        margin-top: 0.15rem;
        transition: transform 150ms ease-in-out;
        transform: translate3d(0, 0.06rem, 0) rotate(${props => (props.showMore ? '180deg' : '0deg')});
    }
    &:hover {
        svg {
            transform: translate3d(0, 0.06rem, 0) rotate(${props => (props.showMore ? '180deg' : '0deg')});
        }
    }
    ${device.mobile} {
        padding: 0.32rem 0;
    }
`
